<template>
  <!-- Authors Table Card -->
  <div style="position: relative">
    <a-card
      :bordered="false"
      class="header-solid h-full"
      :bodyStyle="{ padding: 0 }"
    >
      <!-- <template #title>
        <a-row type="flex" align="middle">
          <a-col :span="24" :md="12">
            <h5 class="font-semibold m-0">Ro'yxatga olingan bemorlar</h5>
          </a-col>
          <a-col
            :span="24"
            :md="12"
            style="
              display: flex;
              align-items: center;
              justify-content: flex-end;
            "
          >
            <a-radio-group
              v-model="authorsHeaderBtns"
              size="small"
              style="margin-right: 25px"
            >
              <a-radio-button value="all">ERKAK</a-radio-button>
              <a-radio-button value="online">AYOL</a-radio-button>
            </a-radio-group>
            <a-button type="primary" @click="openForm()">
              Ro'yxatga olish
            </a-button>
          </a-col>
        </a-row>
      </template> -->
      <a-table
        :columns="columns"
        :data-source="data"
        :pagination="{ pageSize: 25 }"
        :rowKey="
          (record, index) => {
            return index;
          }
        "
      >
        <!-- <template slot="photo" slot-scope="photo">
				<div class="table-avatar-info">
					<a-avatar shape="square" :src="photo" />
				</div>
			</template> -->

        <template slot="avatar" slot-scope="avatar">
          <div class="table-avatar-info">
            <a-avatar shape="square" src="images/patient.png" />
          </div>
        </template>

        <template slot="firstName" slot-scope="firstName">
          <div class="author-info">
            <h6 class="m-0">{{ firstName }}</h6>
          </div>
        </template>

        <template slot="lastName" slot-scope="lastName">
          <div class="author-info">
            <h6 class="m-0">{{ lastName }}</h6>
          </div>
        </template>

        <template slot="middleName" slot-scope="middleName">
          <div class="author-info">
            <h6 class="m-0">{{ middleName }}</h6>
          </div>
        </template>

        <template slot="gender" slot-scope="gender">
          <div class="author-info">
            <h6
              class="m-0"
              :style="gender == 1 ? 'color:#1890FF;' : 'color:orange;'"
            >
              {{
                lang == "uz"
                  ? gender == 1
                    ? "ERKAK"
                    : "AYOL"
                  : gender == 1
                  ? "МУЖЧИНА"
                  : "ЖЕНЩИНА"
              }}
            </h6>
          </div>
        </template>

        <template slot="birthDate" slot-scope="birthDate">
          <div class="author-info">
            <h6 class="m-0">{{ formatDate(birthDate) }}</h6>
          </div>
        </template>

        <template slot="createdAt" slot-scope="createdAt">
          <div class="author-info">
            <h6 class="m-0">{{ formatDate(createdAt) }}</h6>
          </div>
        </template>

        <template slot="confirmedDisease" slot-scope="confirmedDisease">
          <div class="author-info">
            <h6 class="m-0" style="font-weight: 700 !important; color: #1890ff">
              {{ confirmedDisease }}
            </h6>
          </div>
        </template>

        <template slot="editBtn" slot-scope="row">
          <a-button
            type="link"
            :data-id="row.key"
            class="btn-edit"
            @click="openPatientPage(row)"
          >
            {{ lang == "uz" ? "Ko'rish" : "Смотреть" }}
          </a-button>
        </template>
      </a-table>
    </a-card>
    <!-- / Authors Table Card -->
    <!-- <a-modal v-model="visible">
      <RegisterPatient></RegisterPatient>
    </a-modal> -->
  </div>
</template>

<script>
import router from "@/router/index";
// import RegisterPatient from "@/views/Register_patient.vue";
export default {
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    columns: {
      type: Array,
      default: () => [],
    },
  },
  // components: {
  //   RegisterPatient,
  // },
  data() {
    return {
      // Active button for the "Authors" table's card header radio button group.
      authorsHeaderBtns: "all",
      visible: false,
      lang: null,
    };
  },
  mounted() {
    if (localStorage.getItem("lang")) {
      this.lang = localStorage.getItem("lang");
    } else {
      localStorage.setItem("lang", "uz");
    }
  },
  methods: {
    openPatientPage(val) {
      // const afterLastSlash = val._links.patient.href.substring(
      //   val._links.patient.href.lastIndexOf("/") + 1
      // );
      router.replace("/analysis_patient_data?analysis_id=" + val.id);
    },
    formatDate(val) {
      let dateJoin = "";
      for (let t = 0; t < 3; t++) {
        if (val[t].toString().length == 1) {
          dateJoin = dateJoin + "0" + val[t];
        } else {
          dateJoin = dateJoin + val[t];
        }
        if (t != 2) {
          dateJoin = dateJoin + "-";
        }
      }
      return dateJoin;
    },
  },
};
</script>

<style scoped>
.btn-edit {
  color: #28a745;
  border: 1px solid #28a745;
  padding: 10px 20px !important;
}
.btn-edit:hover {
  background-color: #28a745;
  color: #fff;
  transition: 0.2s ease;
}
/* .form_section {
  background: rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(3px);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
} */
/* .sex_color {
	color:red;
} */
</style>