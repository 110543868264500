<!-- 
	This is the tables page, it uses the dashboard layout in: 
	"./layouts/Dashboard.vue" .
 -->

<template>
  <div>
    <a-tabs default-active-key="1" @change="callback">
      <a-tab-pane key="1" tab="Tekshirilmagan bemorlar ro'yxati">
        <a-row :gutter="24" type="flex">
          <a-col :span="24" class="mb-24">
            <CardDoctorTable
              :data="table1Data"
              :columns="lang == 'uz' ? table1Columns : table1ColumnsRu"
            ></CardDoctorTable>
          </a-col>
        </a-row>
      </a-tab-pane>
      <a-tab-pane
        key="2"
        tab="Tashxis qo'yilgan bemorlar ro'yxati"
        force-render
      >
        <CardDoctorCheckedTable
          :data="table2Data"
          :columns="lang == 'uz' ? table2Columns : table2ColumnsRu"
        ></CardDoctorCheckedTable>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
import axios from "../axios.js";
// "Authors" table component.
import CardDoctorTable from "../components/Cards/CardDoctorTable.vue";

// "Projects" table component.
import CardDoctorCheckedTable from "../components/Cards/CardDoctorCheckedTable.vue";

// "Authors" table list of columns and their properties.
const table1Columns = [
  // {
  //   title: "RASMI",
  //   dataIndex: "avatar",
  //   scopedSlots: { customRender: "avatar" },
  // },
  {
    title: "ISMI",
    dataIndex: "patient.firstName",
  },
  {
    title: "FAMILIYASI",
    dataIndex: "patient.lastName",
  },
  {
    title: "SHARIFI",
    dataIndex: "patient.middleName",
  },
  {
    title: "JINSI",
    dataIndex: "patient.gender",
    scopedSlots: { customRender: "gender" },
  },
  {
    title: "TUG'ILGAN SANASI",
    dataIndex: "patient.birthDate",
    scopedSlots: { customRender: "birthDate" },
    class: "text-muted",
    width: 160,
  },
  {
    title: "YARATILGAN SANASI",
    dataIndex: "patient.createdAt",
    scopedSlots: { customRender: "createdAt" },
    class: "text-muted",
    width: 160,
  },
  {
    title: "KOMPYUTER TASHXISI",
    dataIndex: "calculatedDisease",
    scopedSlots: { customRender: "calculatedDisease" },
    class: "text-muted",
  },
  {
    title: "",
    scopedSlots: { customRender: "editBtn" },
    width: 50,
  },
];

const table1ColumnsRu = [
  // {
  //   title: "ФОТО",
  //   dataIndex: "avatar",
  //   scopedSlots: { customRender: "avatar" },
  // },
  {
    title: "ИМЯ",
    dataIndex: "patient.firstName",
  },
  {
    title: "ФАМИЛИЯ",
    dataIndex: "patient.lastName",
  },
  {
    title: "ОТЧЕСТВО",
    dataIndex: "patient.middleName",
  },
  {
    title: "ПОЛ",
    dataIndex: "patient.gender",
    scopedSlots: { customRender: "gender" },
  },
  {
    title: "ДАТА РОЖДЕНИЯ",
    dataIndex: "patient.birthDate",
    scopedSlots: { customRender: "birthDate" },
    class: "text-muted",
    width: 160,
  },
  {
    title: "ДАТА СОЗДАНИЯя",
    dataIndex: "patient.createdAt",
    scopedSlots: { customRender: "createdAt" },
    class: "text-muted",
    width: 160,
  },
  {
    title: "КОМПЬЮТЕРНАЯ ДИАГНОСТИКА",
    dataIndex: "calculatedDisease",
    scopedSlots: { customRender: "calculatedDisease" },
    class: "text-muted",
  },
  {
    title: "",
    scopedSlots: { customRender: "editBtn" },
    width: 50,
  },
];

const table2Columns = [
  {
    title: "RASMI",
    dataIndex: "avatar",
    scopedSlots: { customRender: "avatar" },
  },
  {
    title: "ISMI",
    dataIndex: "patient.firstName",
  },
  {
    title: "FAMILIYASI",
    dataIndex: "patient.lastName",
  },
  {
    title: "SHARIFI",
    dataIndex: "patient.middleName",
  },
  {
    title: "JINSI",
    dataIndex: "patient.gender",
    scopedSlots: { customRender: "gender" },
  },
  {
    title: "TUG'ILGAN SANASI",
    dataIndex: "patient.birthDate",
    scopedSlots: { customRender: "birthDate" },
    class: "text-muted",
    width: 160,
  },
  {
    title: "YARATILGAN SANASI",
    dataIndex: "patient.createdAt",
    scopedSlots: { customRender: "createdAt" },
    class: "text-muted",
    width: 160,
  },
  {
    title: "SHIFOKOR TASHXISI",
    dataIndex: "confirmedDisease",
    scopedSlots: { customRender: "confirmedDisease" },
    class: "text-primary",
  },
  {
    title: "",
    scopedSlots: { customRender: "editBtn" },
    width: 50,
  },
];

const table2ColumnsRu = [
  {
    title: "ФОТО",
    dataIndex: "avatar",
    scopedSlots: { customRender: "avatar" },
  },
  {
    title: "ИМЯ",
    dataIndex: "patient.firstName",
  },
  {
    title: "ФАМИЛИЯ",
    dataIndex: "patient.lastName",
  },
  {
    title: "ОТЧЕСТВО",
    dataIndex: "patient.middleName",
  },
  {
    title: "ПОЛ",
    dataIndex: "patient.gender",
    scopedSlots: { customRender: "gender" },
  },
  {
    title: "ДАТА РОЖДЕНИЯ",
    dataIndex: "patient.birthDate",
    scopedSlots: { customRender: "birthDate" },
    class: "text-muted",
    width: 160,
  },
  {
    title: "ДАТА СОЗДАНИЯ",
    dataIndex: "patient.createdAt",
    scopedSlots: { customRender: "createdAt" },
    class: "text-muted",
    width: 160,
  },
  {
    title: "ДИАГНОЗ ВРАЧА",
    dataIndex: "confirmedDisease",
    scopedSlots: { customRender: "confirmedDisease" },
    class: "text-primary",
  },
  {
    title: "",
    scopedSlots: { customRender: "editBtn" },
    width: 50,
  },
];

// "Authors" table list of rows and their properties.
const table1Data = [
  {
    key: "1",
    avatar: {
      photo: "images/face-3.jpg",
    },
    first_name: "Asadbek",
    last_name: "Alisherovich",
    surname: "Jumayev",
    sex: "ERKAK",
    birth_date: "21.05.2001",
    address: "Toshkent shahar Yashnobod tumani Qamashi ko'chasi 25/1",
    status: 1,
  },
  {
    key: "2",
    avatar: {
      photo: "images/face-3.jpg",
    },
    first_name: "Dilnoza",
    last_name: "Alisher qizi",
    surname: "Sattorova",
    sex: "AYOL",
    birth_date: "21.05.2001",
    address: "Toshkent shahar Yashnobod tumani Qamashi ko'chasi 25/1",
    status: 1,
  },
  {
    key: "3",
    avatar: {
      photo: "images/face-3.jpg",
    },
    first_name: "Asadbek",
    last_name: "Alisherovich",
    surname: "Jumayev",
    sex: "ERKAK",
    birth_date: "21.05.2001",
    address: "Toshkent shahar Yashnobod tumani Qamashi ko'chasi 25/1",
    status: 1,
  },
  {
    key: "4",
    avatar: {
      photo: "images/face-3.jpg",
    },
    first_name: "Asadbek",
    last_name: "Alisherovich",
    surname: "Jumayev",
    sex: "ERKAK",
    birth_date: "21.05.2001",
    address: "Toshkent shahar Yashnobod tumani Qamashi ko'chasi 25/1",
    status: 1,
  },
];
export default {
  components: {
    CardDoctorTable,
    CardDoctorCheckedTable,
  },
  data() {
    return {
      lang: null,
      // Associating "Authors" table data with its corresponding property.
      table1Data: [],

      // Associating "Authors" table columns with its corresponding property.
      table1Columns: table1Columns,
      table1ColumnsRu: table1ColumnsRu,

      // Associating "Projects" table data with its corresponding property.
      table2Data: [],

      table2Columns: table2Columns,
      table2ColumnsRu: table2ColumnsRu,
    };
  },
  mounted() {
    // axios.get("/api/patients").then((res) => {
    //   this.table1Data = res.data._embedded.patients;
    // });
    if (localStorage.getItem("lang")) {
      this.lang = localStorage.getItem("lang");
    } else {
      localStorage.setItem("lang", "uz");
    }
    axios
      .get("/api/analysis/search?size=700&sort=createdAt,desc")
      .then((res) => {
        console.log(res);
        let data = res.data.content;
        this.table1Data = data.filter(
          (item) => !item.doctorId && item.calculatedDisease
        );
        this.table2Data = data.filter(
          (item) => item.doctorId != null && item.calculatedDisease
        );
      });
  },
  methods: {
    callback() {
      // console.log("CALLBACk");
    },
  },
};
</script>

<style lang="scss">
</style>